import * as React from "react"

import Layout from "../components/layout-2"
import Privacy from "../components/privacy"

const IndexPage = () => (
  <Layout path="/">
    <Privacy />
  </Layout>
)

export default IndexPage
